<template>
  <a-modal v-model="modalVisible" :title="modalTitle" :footer="false" :width="1280" :centered="true">
    <div class="menu-modal-container">
      <a-form-model v-if="showType=='maintain'" ref="queryForm" :model="queryParams" layout="inline">
         <a-form-model-item label="保养对象" prop="maintainname">
           <a-select style="width: 200px" placeholder="请选择" v-model="queryParams.maintainname">
             <a-select-option v-for="(item, index) in maintainNameList" :key="index" :value="item.dickey">{{item.dicvalue}}</a-select-option>
           </a-select>
         </a-form-model-item>
        <a-form-model-item label="记录人" prop="recordman">
          <a-input v-model.trim="queryParams.recordman" placeholder="请输入" style="width: 200px"></a-input>
        </a-form-model-item>
        <a-form-model-item label="保养历史时间" prop="queryInstallDate">
          <a-range-picker v-model="queryInstallDate" style="width: 200px"></a-range-picker>
        </a-form-model-item>
         <a-form-model-item style="float: right">
           <a-button @click="resetQueryForm">重置</a-button>
           <a-button type="primary" style="margin-left: 10px;" @click="getTableData(true)">查询</a-button>
         </a-form-model-item>
      </a-form-model>
      <div v-if="usertype==0||usertype==6" style="margin-left: 1135px"><a-button @click="add()" type="primary"><a-icon type="plus"></a-icon>新增</a-button></div>
      <a-table :columns="tableColumns" :data-source="tableData" :rowKey="(record,index)=> index" :loading="tableLoading" :row-class-name="(record, index) => (index%2==0?'table-row-even':'table-row-single')" :pagination="false">
        <span slot="maintainname" slot-scope="value,record">{{value>0?maintainNameList[value-1].dicvalue:''}}</span>
        <span slot="maintaindate" slot-scope="value">
          <a-tooltip :title="value ? moment(value, 'YYYYMMDD').format('YYYY-MM-DD') : ''">
            <span>{{value ? moment(value, 'YYYYMMDD').format('YYYY-MM-DD') : ''}}</span>
          </a-tooltip>
        </span>
        <span slot="recordtime" slot-scope="value">
          <a-tooltip :title="value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''">
            <span>{{value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''}}</span>
          </a-tooltip>
        </span>
        <span slot="delete" slot-scope="value,record">
           <a-button type="link" size="small" @click="deleteConfirm(record.maintainid)">删除</a-button>
        </span>
      </a-table>
      <a-pagination v-model="currentPage" :total="pagination.total" :page-size="pagination.pageSize" @change="pageChange" :show-total="(total, range) => `共${total}条`" style="margin-top: 20px; text-align: right;">
        <template slot="buildOptionText" slot-scope="props">
          <span>{{ props.value }}条/页</span>
        </template>
      </a-pagination>
    </div>
    <a-modal :visible="addVisible" :title="modalTitle" @cancel="addVisible=false">
      <template slot="footer">
        <a-button @click="addVisible=false">取消</a-button>
        <a-button type="primary" @click="confirm">确认</a-button>
      </template>
      <div class="simple-query">
        <a-form-model ref="formData" :model="formData" class="query-form" :rules="formRules" :label-col="{span:8}" :wrapper-col="{span:12}">
          <a-form-model-item v-if="showType=='maintain'" label="保养对象" prop="maintainname">
            <a-select style="width: 240px" placeholder="请选择" v-model="formData.maintainname">
              <a-select-option v-for="(item, index) in maintainNameList" :key="index" :value="item.dickey">{{item.dicvalue}}</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item v-if="showType=='maintain'" label="保养历史时间" prop="maintaindate">
            <a-date-picker @change="onChange" style="width: 240px"/>
          </a-form-model-item>
          <a-form-model-item label="记录人" prop="recordman">
            <a-input v-model.trim="formData.recordman" placeholder="请输入" style="width: 240px"></a-input>
          </a-form-model-item>
        </a-form-model>
      </div>
    </a-modal>
  </a-modal>
</template>

<script>
import pagination from "@/mixins/pagination";
import {mapState} from "vuex";
import moment from "moment";
import {getCache, getTimeRange} from "U/index";
import {addSceneMaintain, deleteSceneMaintain, getSceneMaintainListByCondition} from "A/monitoring";
import {getDictByDicType} from "A/xtpz";

export default {
  name: "SceneMaintainDetail",
  mixins: [pagination],
  props: {
    visible: {
      default: false
    },
    showType: {
      default: ''
    },
    maintainDetailData: {
      default: null
    },
  },
  components: {},
  data() {
    return {
      moment,
      maintainNameList:[],
      modalVisible: false,
      addVisible:false,
      queryParams:{
        maintaintype:'',
        maintainname:'',
        maintaindate:'',
        scenetemplateid:'',
        scenetype:'',
        recordman:'',
      },
      queryInstallDate:null,
      formData: {
        maintainid:'',
        maintaintype:'',
        maintainname:'',
        maintaindate:'',
        scenetemplateid:'',
        scenetype:'',
        recordman:'',
        recordtime:'',
      },
      formRules: {
        maintainname: [{required: true, message: '请选择保养对象'}],
        maintaindate: [{required: true, message: '请选择保养时间'}],
        recordman: [{required: true, message: '请输入记录人'}],
      },
      usertype:'',
      tableColumns: [
        {
          title: '场景模板名称',
          dataIndex: 'templatename',
          key: 'templatename',
          align: 'center',
          ellipsis: true,
        },
        {
          title: '保养对象',
          dataIndex: 'maintainname',
          key: 'maintainname',
          align: 'center',
          ellipsis: true,
          scopedSlots: { customRender: 'maintainname' },
        },
        {
          title: '保养历史时间',
          dataIndex: 'maintaindate',
          key: 'maintaindate',
          align: 'center',
          ellipsis: true,
          scopedSlots: { customRender: 'maintaindate' },
          width: 140
        },
        {
          title: '记录人',
          dataIndex: 'recordman',
          key: 'recordman',
          ellipsis: true,
          align: "center",
        },
        {
          title: '记录时间',
          dataIndex: 'recordtime',
          key: 'recordtime',
          align: 'center',
          ellipsis: true,
          scopedSlots: { customRender: 'recordtime' },
          width: 140
        },
        {
          title: '',
          dataIndex: 'delete',
          key: 'delete',
          ellipsis: true,
          align: "center",
          scopedSlots: { customRender: 'delete' },
          width: 70
        },
      ],
      tableData: [],
      tableLoading: false,
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo
    }),
    modalTitle() {
      if(this.showType=='maintain'){
        return '场景保养信息'
      }
    }
  },
  watch: {
    visible(val) {
      if (val) {
        this.queryParams.scenetemplateid=this.maintainDetailData.scenetemplateid
        this.queryParams.scenetype=this.maintainDetailData.scenetype
        this.modalVisible = true;
        let logininfo = getCache('logininfo', true);
        if(logininfo) {
          this.usertype = logininfo.usertype;
        }
        this.getMaintainNameList()
        this.getTableData(true);
      }
    },
    modalVisible(val) {
      if (!val) {
        this.$emit('update:visible', false);
      }
    },
    queryInstallDate(val) {
      let {start, end} = getTimeRange(val);
      this.queryParams.starttime = start;
      this.queryParams.endtime = end;
    },
  },
  created() {
    this.modalVisible = this.visible;
  },
  methods: {
    resetQueryForm() {
      this.queryInstallDate = [];
      this.$refs.queryForm.resetFields();
    },
    getMaintainNameList(){
      getDictByDicType({dictype:this.queryParams.scenetype==1?31:38}).then(res=>{
        if(res&&res.returncode=='0'){
          this.maintainNameList = res.item
        }
      })
    },
    getTableData(firstPage) {
      if (firstPage) {
        this.currentPage = 1;
      }
      this.tableLoading = true;
      let params = {
          ...this.queryParams,
          scenemaintain:true,
          pageno: this.currentPage,
          pagesize: this.pagination.pageSize
      };
      getSceneMaintainListByCondition(params).then(res => {
        this.tableLoading = false;
        if (res && res.returncode == '0') {
          this.tableData = res.item;
          this.pagination.total = res.count;
        }
      }).catch(err => {
        this.tableLoading = false;
      })
    },
    add(){
      this.addVisible=true
    },
    deleteConfirm(maintainid){
      this.$confirm('确定要删除该数据？',{
        type:'warning',
        centered:true,
      }).then(()=>{
        deleteSceneMaintain({maintainid:maintainid}).then(res=>{
          if(res && res.returncode=='0'){
            this.$message.success("删除成功")
            this.getTableData(true)
          }
        })
      }).catch(()=>{
      });
    },
    onChange(date, dateString) {
      this.formData.maintaindate=moment(dateString,"YYYY-MM-DD").format("YYYYMMDD")
    },
    confirm(){
      if(this.showType=='params'){
        this.formData.maintaindate=""
        this.formData.maintainname=0
      }
      this.$refs.formData.validate(valid => {
        if (valid) {
          this.showLoading();
          this.formData.recordtime=moment(new Date()).format("YYYYMMDDHHmmss")
          this.formData.scenetemplateid=this.maintainDetailData.scenetemplateid
          this.formData.scenetype=this.maintainDetailData.scenetype
          let params = {
            ...this.formData
          };
          addSceneMaintain(params).then(res => {
            this.hideLoading();
            if (res && res.returncode == '0') {
              this.$message.success('操作成功');
              this.$emit('get-operation-result', 'success', '操作成功');
              this.addVisible = false;
              this.getTableData(true)
            } else {
              this.$message.error(res.errormsg || '操作失败');
            }
          })
        }
      })
    },
  }
}
</script>

<style scoped>

</style>